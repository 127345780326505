import React from "react";

function IconImage(props) {
  const defaultProps = {
    width: 66,
    height: 66,
  };

  props = { ...defaultProps, ...props };

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" {...props}>
      <circle cx="50" cy="30" r="25" fill="#FFCCBB" />
      <circle cx="40" cy="25" r="3" fill="#000000" />
      <circle cx="60" cy="25" r="3" fill="#000000" />
      <polygon points="50,32 48,38 52,38" fill="#000000" />
      <path d="M 40 45 Q 50 52, 60 45" fill="none" stroke="#000000" />
    </svg>
  );
}

export default IconImage;
