import { useState, useEffect, useRef } from "react";
import logo from "./logo.svg";
import "./App.css";
import IconImage from "./components/icons/IconImage";

function App() {
  const [input, setInput] = useState("");
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFirstRequest, setIsFirstRequest] = useState(true);
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const generateResponse = (question) => {
    if (isLoading) return;

    setIsFirstRequest(false);
    setIsLoading(true);

    setMessages((prevMessages) => [
      ...prevMessages,
      { text: question, type: "user" },
      { text: "Loading...", type: "loading" },
    ]);

    const host = `https://ai.fanheroapi.com`;
    fetch(`${host}/ask`, {
      body: JSON.stringify({ question }),
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: "Bearer foo",
      }),
    })
      .then(async (response) => {
        const data = await response.json();
        setMessages((prevMessages) => [
          ...prevMessages.slice(0, -1), // Remove the "Loading..." message
          { text: data.response, type: "bot" },
        ]);
        setIsLoading(false);
      })
      .catch((error) => {
        setMessages((prevMessages) => [
          ...prevMessages.slice(0, -1), // Remove the "Loading..." message
          {
            text: "FanHero bot did not respond. Try to regenerate the response in a minute.",
            type: "error",
          },
        ]);
        setIsLoading(false);
      });
    setInput("");
  };

  return (
    <div className="App">
      <header>
        <div>
          <a target="_blank" href="https://fanhero.com/" rel="noreferrer">
            FanHero
          </a>
        </div>
        <div>
          <a target="_blank" href="https://fanhero.com" rel="noreferrer">
            source
          </a>
        </div>
      </header>

      <main>
        <div>
          <img src={logo} alt="Logo" />
          <h1>FanHero Gen AI</h1>
          <div className="scrollable">
            {!isFirstRequest && (
              <div>
                {messages.map((message, index) => (
                  <div className="questions-answers" key={index}>
                    <p
                      style={{
                        color: message.type === "bot" ? "#0657F9" : "#000",
                        marginTop: 30,
                      }}
                    >
                      <b>
                        {message.type === "bot"
                          ? "FanHero Bot"
                          : message.type === "loading"
                            ? ""
                            : "You"}
                      </b>
                    </p>
                    <div>
                      {message.type === "loading" ? (
                        <p className="loading-dots">
                          <span>.</span>
                          <span>.</span>
                          <span>.</span>
                        </p>
                      ) : (
                        <pre style={{ marginBottom: 30 }}>
                          <p>{message.text}</p>
                        </pre>
                      )}
                    </div>
                  </div>
                ))}
                <div ref={messagesEndRef} />
                <br />
                <br />
                <br />
                <br />
                <br />
              </div>
            )}
          </div>
          <div className="white-fade"></div>
        </div>
      </main>

      <footer>
        <div className="input-wrapper">
          <input
            type="text"
            placeholder="Init a conversation with your content"
            value={input}
            autoFocus
            onFocus={(e) => (e.target.placeholder = "")}
            onBlur={(e) => (e.target.placeholder = "Talk with your documents")}
            onChange={(e) => {
              setInput(e.target.value.substring(0, 4000));
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter" && input.trim() && !isLoading) {
                generateResponse(input.trim());
              }
            }}
          />
          <IconImage
            className={`send ${isLoading ? "disabled" : ""}`}
            onClick={() => !isLoading && generateResponse(input.trim())}
          />
        </div>
        <div className="input-wrapper"></div>
        <span>POC using concepta.fanhero.tv.</span>
      </footer>
    </div>
  );
}

export default App;
